import {
  mergeWithDescriptors,
  useEvaluation,
  useManagerFormValue,
} from '@manager'

type FieldPropOptions<D> = { default?: D; values?: Record<string, any> }
type FieldPropFn<T> = (evaluate: (expression: string) => T) => T

type ResolvedFieldProp = {
  state: any
  fn: FieldPropFn<any>
  options: FieldPropOptions<any>
}

interface UseFieldPropsOptions {
  relativePath?: MaybeRefOrGetter<string | undefined>
  values?: MaybeRefOrGetter<Record<string, any>>
}

export const useFieldProps = (options: UseFieldPropsOptions = {}) => {
  const {
    resolveExpression: _resolveExpression,
    parse: _parse,
    evaluate: _evaluate,
  } = useEvaluation()
  const { formValue } = useManagerFormValue()
  const globalOptions = options

  const evaluate = (fn: FieldPropFn<any>, options: FieldPropOptions<any>) => {
    const evaluate = (expression: string) => {
      return _evaluate({
        expression: _parse(
          _resolveExpression(expression, toValue(globalOptions.relativePath)),
        ),
        default: options.default,
        values: mergeWithDescriptors(formValue.value, {
          ...toValue(globalOptions.values),
          ...options.values,
        }),
      })
    }
    return fn(evaluate) ?? options.default
  }

  const props = shallowRef<ResolvedFieldProp[]>([])
  const createFieldProp = <T = any, D = T>(
    fn: FieldPropFn<T>,
    options?: FieldPropOptions<D>,
  ) => {
    const state = ref<T>(options?.default as T)
    const resolved = {
      state,
      fn,
      options: {
        default: options?.default,
        values: options?.values ?? {},
      },
    } satisfies ResolvedFieldProp

    props.value = [...props.value, resolved]

    return state
  }
  watch(
    [() => toValue(globalOptions.values), formValue, props],
    () => {
      for (const { state, fn, options } of props.value) {
        state.value = evaluate(fn, options)
      }
    },
    { immediate: true, deep: true },
  )

  return { createFieldProp }
}
