import { FieldInputTypes, FieldValueType, type INodeField } from '@manager'

const getTypeFromValueType = (valueType: FieldValueType) => {
  switch (valueType) {
    case FieldValueType.DECIMAL:
    case FieldValueType.INTEGER:
    case FieldValueType.PERCENTAGE: {
      return 'number'
    }
    default: {
      return 'text'
    }
  }
}

export const parseFieldType = (node: INodeField) => {
  switch (node.type) {
    case FieldInputTypes.PASSWORD: {
      return 'password'
    }
    case FieldInputTypes.TEXT: {
      return 'textarea'
    }
    case FieldInputTypes.DATE: {
      return 'datepicker'
    }
    case FieldInputTypes.GOOGLE_ADDRESS:
    case FieldInputTypes.DROPDOWN: {
      return 'listbox'
    }
    case FieldInputTypes.OPTIONS: {
      return 'radio'
    }
    case FieldInputTypes.CHECKBOX: {
      return 'checkbox'
    }
    default:
    case FieldInputTypes.INPUT: {
      return getTypeFromValueType(node.valueType)
    }
  }
}
