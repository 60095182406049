import {
  FieldRequiredLevel,
  joinPath,
  parseFieldType,
  parseSizeToGrid,
  resolveNodeId,
  useFieldCast,
  useFieldValidation,
  useFieldProps,
  FieldUsage,
  useManagerFormEvaluation,
} from '@manager'
import type { IManagerFieldProps } from '@manager/components/Field/types'

export const useField = <Name extends string>(
  props: IManagerFieldProps,
  emit: (name: Name, ...args: any[]) => void,
) => {
  const evaluate = useManagerFormEvaluation()

  // Props
  const path = computed(() => joinPath(props.parentPath!, props.node.name))
  const id = computed(() =>
    joinPath(props.parentPath!, resolveNodeId(props.node)),
  )
  const type = parseFieldType(props.node)
  const colSize = computed(() => parseSizeToGrid(props.node.size))
  const label = computed(() => {
    let label = props.node.label

    if (props.node.requiredLevel === FieldRequiredLevel.REQUIRED) {
      label += ' *'
    }

    return label
  })

  const { cast } = useFieldCast(props.node)

  // Validation
  const validation = useFieldValidation(props.node, path)

  // Model value
  const parentValue = useVModel(
    props as Required<IManagerFieldProps>,
    'parentValue',
    emit,
  )
  const modelValue = computed({
    get: () => parentValue.value[props.node.name],
    set: (value) => {
      parentValue.value[props.node.name] = value
    },
  })

  // Cast value and fallback to default
  modelValue.value = cast(modelValue.value, props.node.defaultValue)
  validation.handleChange(parentValue.value[props.node.name], false)

  const help = computed(() => {
    // if (props.node.description) return props.node.description
    if (props.node.requiredLevel === FieldRequiredLevel.RECOMMENDED)
      return 'This field is recommended'
  })

  // Evaluate
  const handleChange = useDebounceFn((v: unknown, shouldValidate = true) => {
    validation.handleChange(v, shouldValidate)
    if (props.node.usage === FieldUsage.INTERACTIVE && validation.meta.valid) {
      evaluate()
    }
  }, 100)

  const handleBlur = useDebounceFn((e?: Event, shouldValidate = true) => {
    validation.handleBlur(e, shouldValidate)
  }, 100)

  tryOnMounted(() => {
    watchWithFilter(modelValue, (value) => handleChange(value), {
      eventFilter: (invoke, options) => {
        const [value, oldValue] = options.args
        if (!isEqual(value, oldValue)) invoke()
      },
    })
  })

  // Props
  const initialValue = modelValue.value
  const { createFieldProp } = useFieldProps({
    relativePath: props.parentPath,
    values: () => ({
      $initialValue: initialValue,
      $value: modelValue.value,
    }),
  })
  const disabled = createFieldProp<boolean>(
    (evaluate) => {
      // If disabled is not defined or is a boolean, return it
      const disabled = props.node.disabled
      if (!disabled || typeof disabled === 'boolean')
        return (disabled as boolean) ?? false
      // Otherwise, evaluate the expression
      return evaluate(disabled)
    },
    { default: false },
  )
  const min = createFieldProp(
    (evaluate) => {
      // If min is not a string, return it
      const min = props.node.min
      if (typeof min !== 'string') return min
      // Otherwise, evaluate the expression
      return evaluate(min)
    },
    { default: undefined },
  )
  const max = createFieldProp(
    (evaluate) => {
      // If max is not a string, return it
      const max = props.node.max
      if (typeof max !== 'string') return max

      // Otherwise, evaluate the expression
      return evaluate(max)
    },
    { default: undefined },
  )

  watch(disabled, () => validation.handleReset())

  return {
    id,
    type,
    colSize,
    label,
    path,
    modelValue,
    parentValue,
    validation,
    help,
    disabled,
    min,
    max,
    handleChange,
    handleBlur,
  }
}
