import type { Ref } from 'vue'
import type { IManagerFormNodeValue, INodeField } from '@manager'

export const useFieldData = (
  node: INodeField,
  parentValue: Ref<IManagerFormNodeValue>,
  callback: (value: any) => any = (value) => value,
) => {
  const registerData = () => {
    if (`data_${node.name}` in parentValue.value) return
    Object.defineProperty(parentValue.value, `data_${node.name}`, {
      get() {
        return callback(this[node.name])
      },
      enumerable: false,
    })
  }

  return { registerData }
}
